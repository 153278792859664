import * as React from "react"
import { FC, useContext } from "react"
import Layout from "../../social-supermarket/components/Layout"
import SEO from "../../social-supermarket/components/Seo"
import CheckoutPage from "../../social-supermarket/components/checkout/CheckoutPage"
import { GiftOrderContext } from "../../social-supermarket/context/GiftOrderProvider"
import { LoginContext, OrderContextType } from "@social-supermarket/social-supermarket-components"

interface Props {}

const GiftingCheckout: FC<Props> = () => {
  const loginContext = useContext(LoginContext)
  const context = useContext<OrderContextType>(GiftOrderContext)

  return loginContext.user ? (
    <Layout>
      <SEO title={"Gifting Checkout"} description={""} />
      <CheckoutPage context={context} multiRecipient />
    </Layout>
  ) : (
    <Layout />
  )
}

export default GiftingCheckout
